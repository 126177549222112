import React from 'react';

export default function App() {
    React.useEffect(() => {
        if (window.location.pathname = '/') {
            window.location.href = 'https://andregosling.com';
        }
    })

    return(<></>)
}